<template>
    <section id="firebaseui-auth-container"></section>
</template>
<script src="https://www.gstatic.com/firebasejs/ui/6.0.1/firebase-ui-auth.js"></script>
<script>
import "firebaseui";
import "firebaseui/dist/firebaseui.css";
  export default {
    name: 'login',
    mounted(){
        var ui = new firebaseui.auth.AuthUI(this.$auth);
        var uiConfig = {
            signInSuccessUrl: "/",
            signInOptions: [
                {
                    provider: this.$provider,
                    requireDisplayName: false
                }
            ]
        };
        ui.start("#firebaseui-auth-container", uiConfig);
    },
  }
</script>
<style>
</style>

<template>
    <div>
        <base-header type="gradient-success" class="pb-4 pt-5 pt-md-8">
            <!-- Card stats -->
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <tabel-user title="Tabel Reservasi"></tabel-user>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
  import TabelUser from './Tables/TabelReservasi'
  export default {
    name: 'tables',
    components: {
      TabelUser
    },
    mounted(){
      
    }
  };
</script>
<style></style>

<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{title}}
          </h3>
        </div>
      </div>
      
    <div class="row">
      <div class="col-md-12 pr-4 text-right">
        <i style="font-size:0.8rem">*) data akan direfresh otomatis setiap 30 detik</i>
      </div>
    </div>
    </div>
    <div class="row">
      <div class="col-md-12 pr-5 pl-5">
        <div class="row">
          <date-picker placeholder="Tanggal Mulai" v-model="date1" class="col-md-6" valueType="format"></date-picker>
          <date-picker placeholder="Tanggal Selesai" v-model="date2" class="col-md-6" valueType="format"></date-picker>
        </div>
        <div class="row">
          <base-input inputClasses="col-md-12" addonLeftIcon="ni ni-zoom-split-in" v-model="search"></base-input>
        </div>
        <div class="row">
          <div class="col-md-12 mb-3">
            <button 
                type="button"
                class="btn btn-block btn-primary"
                @click="getData"
            >
                <span class="text-light">Refresh Data</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    
    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="tableData">
        <template slot="columns">
          <th>No Reservasi</th>
          <th>Nama</th>
          <th>Tanggal</th>
          <th>Status</th>
          <th></th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{row.NoReservasi}}</span>
              </div>
            </div>
          </th>
          <td class="budget">
            {{row.Nama}}
          </td>
          <td class="budget">
            {{row.Tanggal | moment}}
          </td>
          <td>
            <badge class="badge-dot mr-4" :type="statusCode[row.Batal]">
              <i :class="`bg-${statusCode[row.Batal]}`"></i>
              <span class="status">{{statusList[row.Batal]}}</span>
            </badge>
          </td>
          <td>
            <div class="media-body budget" v-if="row.Batal != '1'">
                <button 
                    type="button"
                    class="btn btn-block btn-primary"
                    v-clipboard:copy="message(row)"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                >
                    <span class="text-light">Generate Link</span>
                </button>
                <!-- <base-button @click="resetPass" type="info">Reset Password</base-button> -->
              </div>
          </td>

        </template>

      </base-table>
    </div>
    <div class="card-footer d-flex justify-content-end"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <base-pagination :total="meta.total"
                       :perPage="meta.per_page"
                       v-model="page"
      ></base-pagination>
    </div>

  </div>
</template>
<script>
  import debounce from "lodash.debounce";
  import moment from "moment";
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import BaseInput from '../../components/BaseInput.vue';
  export default {
    components: { DatePicker, BaseInput },
    name: 'projects-table',
    props: {
      type: {
        type: String
      },
      title: String
    },
    data() {
      return {
        tableData: [
        ],
        meta: {
          total : 0,
          per_page : 0,
        },
        page: 1,
        selected: null,
        search: "",
        date1: moment().format('YYYY-MM-DD'),
        date2: moment().format('YYYY-MM-DD'),
        statusList: {
          0 : "Aktif",
          1 : "Batal",
        },
        statusCode: {
          0 : "success",
          1 : "danger",
        },
      }
    },
    created(){
      this.debouncedWatch = debounce((newValue) => {
        this.$http
        .get(process.env.VUE_APP_API_URL+"&startDate=" + this.date1 + "&endDate=" + this.date2 + "&page="+this.page+ "&search="+newValue)
        .then((response) => {
          this.tableData = response.data.data.data;
          this.meta.per_page = response.data.data.per_page;
          this.meta.total = response.data.data.total;
        });
      }, 500);
      setInterval(function () {
        this.getData();
      }.bind(this), 30000);
    },
    beforeUnmount() {
      this.debouncedWatch.cancel();
    },
    mounted() {
      this.$http
      .get(process.env.VUE_APP_API_URL+"&startDate=" + this.date1 + "&endDate=" + this.date1 + "")
      .then((response) => {
        this.tableData = response.data.data.data;
        this.meta.per_page = response.data.data.per_page;
        this.meta.total = response.data.data.total;
      });
    },
    watch:{
      page(newVal){
        console.log(newVal)
        this.getData();
      },
      date1(newVal){
        console.log(newVal)
        this.getData();
      },
      date2(newVal){
        console.log(newVal)
        this.getData();
      },
      search(newVal){
        this.debouncedWatch(newVal);
        // console.log(newVal)
        // this.$http
        // .get(process.env.VUE_APP_API_URL+"&startDate=" + this.date1 + "&endDate=" + this.date2 + "&page="+this.page+ "&search="+this.search)
        // .then((response) => {
        //   this.tableData = response.data.data;
        //   this.meta.per_page = response.data.per_page;
        //   this.meta.total = response.data.total;
        // });
      }
    },
    methods:{
      onCopy: function () {
        this.$toast.open({
          message: "Teks sudah disalin!",
          type: "success",
          duration: 3000,
          dismissible: true
        })
      },
      onError: function () {
        this.$toast.open({
          message: "Gagal menyalin teks!",
          type: "error",
          duration: 3000,
          dismissible: true
        })
      },
      message(row){
        var dates = new Date(row.UntukJam);
        var maxReg = moment(dates).subtract(15, "minute").format("HH:mm");
        var praktekDate = moment(dates).format("DD-MM-YYYY");
        var praktekTime = moment(dates).format("HH:mm");
        return "Terima kasih telah mendaftar melalui WhatsApp Bunda\n"+
        "Yth. Bapak/Ibu/Pasien\n"+
        "\n"+
        "*No. Antrian poliklinik: "+row.NoUrut+"*\n"+
        "*Jam Registrasi ulang maks: "+maxReg+"*\n"+
        "*Tanggal periksa: "+praktekDate+"*\n"+
        "Dengan Dokter: "+row.NamaDOkter+"\n"+
        "Jam praktek : "+praktekTime+"\n"+
        "Link QR Code Self-Registration: https://reservasi.puribunda.com/qrcode/"+row.NoReservasi+"\n"+
        "\n"+
        "Mohon ingat hal-hal berikut:\n"+
        "✴ Datang registrasi ulang tepat waktu dijam yang sudah ditentukan, Jika jam registrasi melebih jam maksimal yang ditentukan, maka akan mendapat nomor antrian poliklinik yang baru\n"+
        "✴ Mohon ambil antrian reservasi pada mesin saat tiba di puri bunda\n"+
        "\n"+
        "Menjaga kesehatan hari ini memberi harapan yang lebih baik untuk hari esok.";
      },
      getData(){
        this.$http
        .get(process.env.VUE_APP_API_URL+"&startDate=" + this.date1 + "&endDate=" + this.date2 + "&page="+this.page+ "&search="+this.search)
        .then((response) => {
          this.tableData = response.data.data.data;
          this.meta.per_page = response.data.data.per_page;
          this.meta.total = response.data.data.total;
        });
      }
    },
    filters: {
      moment: function (date) {
        return moment(date).format('DD MMMM YYYY');
      }
    }
  }
</script>
<style>
</style>

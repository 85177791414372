<template>
    <div>
    </div>
</template>
<script>
import { signOut } from "firebase/auth";
export default {
    mounted(){
      signOut(this.$auth).then(() => {
        this.$router.push("login")
      }).catch(() => {
        this.$router.options.history.state.back
      });
    },
}
</script>